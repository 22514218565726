@tailwind base;
@tailwind components;
@tailwind utilities;
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";

body {
  margin: 0;
  font-family: Avenir, Roboto, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #060302;
}

@font-face {
  font-family: 'Skranji';
  src: url('/public/assets/fonts/Skranji-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Skranji';
  src: url('/public/assets/fonts/Skranji-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Avenir';
  src: url('/public/assets/fonts/AvenirLTStd-Roman.otf') format('truetype');
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container-app {
  width: 100%;
  min-height: calc(100vh - 64px);

  @media (max-width: 800px) {
    min-height: calc(100vh - 56px);
  }
}

.MuiButton-root {
  text-transform: none !important;
  font-family: Avenir;
}

.custom-container {
  max-width: 1644px !important;
}

.container-page {
  max-width: 1364px !important;
}

.footer {
  margin-top: auto
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #96846a;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}